<template>
	<div class="mainContainer">
	    <div class="video-container">
	        <div>
	            <video name="videoElement" class="centeredVideo" controls autoplay>
	                Your browser is too old which doesn't support HTML5 video.
	            </video>
	        </div>
	    </div>
	</div>
	<!-- <video style="width:100%;" controls autoplay src="//player.alicdn.com/resource/player/qupai.mp4"></video> -->
</template>

<script>
export default{
	data(){
		return{
			player: undefined,
			mediaDataSource: {
				cors: true,
			    type: 'flv',
				isLive: true,
				hasAudio: true,
				hasVideo: true,
				url: 'https://live.treasury.com.cn/test/ttt.flv'
			}
		}
	},
	mounted() {
		this.flv_load()
	},
	methods:{
		flv_load(){
			let that = this
			document.addEventListener('DOMContentLoaded', function () {
			    that.flv_load_mds(that.mediaDataSource)
			});
		},
		flv_load_mds(mediaDataSource) {
		    // let element = document.getElementById('videoElement');
			let element = document.getElementsByName('videoElement')[0];
		    if (typeof this.player !== "undefined") {
		        if (this.player != null) {
		            this.player.unload();
		            this.player.detachMediaElement();
		            this.player.destroy();
		            this.player = null;
		        }
		    }
		    this.player = flvjs.createPlayer(mediaDataSource, {
		        enableWorker: false,
		        lazyLoadMaxDuration: 3 * 60,
		        seekType: 'range',
		    });
		    this.player.attachMediaElement(element);
		    this.player.load();
		}
	}
}
</script>

<style lang="scss" scoped>
.mainContainer {
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
	// position: fixed;
	// top: 0;
	z-index: 2;
}
@media screen and (min-width: 1152px) {
    .mainContainer {
        display: block;
        width: 1152px;
        margin-left: auto;
        margin-right: auto;
    }
}
.video-container {
	position: relative;
}

.video-container:before {
    display: block;
    content: "";
    width: 100%;
    padding-bottom: 56.25%;
}

.video-container > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.video-container video {
    width: 100%;
    height: 100%;
}
.centeredVideo {
    display: block;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
}
</style>

const state = {
  commentType: '',
	commentList: {
    list: [
      {
        id: 1,
        img: 'https://assetcloud-wxapp.oss-cn-hangzhou.aliyuncs.com/3.0.0/meeting_default_avatar.png',
        title: 'BaekLi',
        comment: '2020！'
      },
      {
        id: 2,
    	  img: 'https://assetcloud-wxapp.oss-cn-hangzhou.aliyuncs.com/3.0.0/meeting_default_avatar.png',
        title: 'BBH',
        comment: '这真是一场美妙的直播！'
      },
      {
        id: 3,
        img: 'https://assetcloud-wxapp.oss-cn-hangzhou.aliyuncs.com/3.0.0/meeting_default_avatar.png',
        title: 'LLL',
        comment: '2020中国企业财资管理创新发展报告发布会这真是一场美妙的直播！',
        time: '2020/10/14 16:03'
      },
      {
        id: 2,
        img: 'https://assetcloud-wxapp.oss-cn-hangzhou.aliyuncs.com/3.0.0/meeting_default_avatar.png',
        title: 'BBH',
        comment: '这真是一场美妙的直播！'
      },
      {
        id: 1,
        img: 'https://assetcloud-wxapp.oss-cn-hangzhou.aliyuncs.com/3.0.0/meeting_default_avatar.png',
        title: 'BaekLi',
        comment: '2020中国企业财资管理创新发展报告发布会这真是一场美妙的直播！',
        time: '2020/11/14 16:03'
      },
      {
        id: 2,
        img: 'https://assetcloud-wxapp.oss-cn-hangzhou.aliyuncs.com/3.0.0/meeting_default_avatar.png',
        title: 'BBH',
        comment: '这真是一场美妙的直播！'
      }
    ],
    total: 9
  },
  replayList: [
    {
      pic: 'https://assetcloud-wxapp.oss-cn-hangzhou.aliyuncs.com/3.0.0/meeting_default_avatar.png',
      title: '《财务思维课》和《一本书读懂财报》读后线上分享会',
      time: '00:35:00',
      url: '//player.alicdn.com/resource/player/qupai.mp4'
    },
    {
      pic: 'https://assetcloud-wxapp.oss-cn-hangzhou.aliyuncs.com/3.0.0/meeting_default_avatar.png',
      title: '《财务思维课》和《一本书读懂财报》读后线上分享会',
      time: '00:35:00',
      url: ''
    }
  ]
}

const mutations = {
  SET_COMMENT_LIST: (state, data) => {
    state.commentList = data
  },
  SET_COMMENT_TYPE: (state, data) => {
    state.commentType = data
  }
}

const actions = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

<template>
	<div>
		<video id="container" class="video-js vjs-big-play-centered"  width="700" height="450" controls preload="auto"
		data-setup="{}"></video>
	</div>
</template>

<script>
export default{
	mounted() {
		this.initVideo()
	},
	methods:{
		initVideo(){
			// let player = videojs('container',{
			//   //像data-setup那样设置的参数
			// },function onPlayerReady(){
			//   //视频播放器初始化完毕，就会调用这个回调函数
			//   this.src({
			//     src: "rtmp://live.treasury.com.cn/test/ttt",
			//     type:'rtmp/flv'
			//   });
			// });
			if($("#container").length > 0) {
			    let player = this.$video('container');
			    player.dispose();
			  }
			 
			 
			//重载
			let player =  this.$video("container");  //初始化视频
			//重置video的src
			player.src({
			  src: "//player.alicdn.com/resource/player/qupai.mp4",
			  type:'rtmp/flv'
			});
			player.load();
			player.play();
		}
	}
}
</script>

<style>
</style>

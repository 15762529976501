<template>
  <div class="comments" @scroll="onLoadMore" ref="comments">
    <div v-if="commentList.total===0" class="empty_message">
      {{emptyMessage}}
    </div>
    <div v-else>
      <div class="tip">{{commentList.list.length===commentList.total?totalMessage:loadMessage}}</div>
      <div class="comment" v-for="(item,index) in commentList.list">
        <div class="time" v-show="item.time!==''">
          {{item.time}}
        </div>
        <div class="chat">
          <img class="pic" :src="item.img" />
          <div class="right">
            <div class="title">{{item.title}}</div>
            <!-- todo: 通过比对id -->
            <div :class="['content', item.id===3?'myself':'']">{{item.comment}}</div>
          </div>
        </div>
      </div>
      <!-- <div class="tip_bottom">{{endMessage}}</div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data () {
    return {
      totalMessage: '已全部加载完毕',
      loadMessage: '上滑加载更多...',
      endMessage: '-- END --',
      emptyMessage: '快来发表你的想法吧！'
    }
  },
  computed: {
    commentList () {
      return this.$store.state.index.commentList
    },
    commentType () {
      return this.$store.state.index.commentType
    }
  },
  watch: {
    commentList (old, newCom) {
      this.scrollToBottom()
    }
  },
  mounted () {
  },
  methods: {
    scrollToBottom (param) {
      let ele = this.$refs.comments
      if (this.commentType !== 'load' || param === 'input') {
        this.$nextTick(() => {
          ele.scrollTop = ele.scrollHeight
        })
      } else if (this.commentType !== 'send') {
        this.$nextTick(() => {
          ele.scrollTop = '30'
        })
      }
    },
    onLoadMore (e) {
      if (e.target.scrollTop === 0 && this.commentList.list.length !== this.commentList.total) {
        let timer = null
        if (timer) clearTimeout(timer)
        timer = setTimeout(() => {
          console.log('到顶了...')
          // todo: 查询分页接口
          let data = [
            {
              id: 2,
              img: 'https://assetcloud-wxapp.oss-cn-hangzhou.aliyuncs.com/3.0.0/meeting_default_avatar.png',
              title: 'BBH',
              comment: '这真是一场美妙的直播！'
            },
            {
              id: 3,
              img: 'https://assetcloud-wxapp.oss-cn-hangzhou.aliyuncs.com/3.0.0/meeting_default_avatar.png',
              title: 'LLL',
              comment: '这真是一场美妙的直播！'
            },
            {
              id: 1,
              img: 'https://assetcloud-wxapp.oss-cn-hangzhou.aliyuncs.com/3.0.0/meeting_default_avatar.png',
              title: 'BaekLi',
              comment: '1111！'
            },
            ...this.commentList.list
          ]
          this.$store.commit('index/SET_COMMENT_LIST', {
            ...this.commentList,
            list: data
          })
          this.$store.commit('index/SET_COMMENT_TYPE', 'load')
        }, 1000)
      }
    }
  }
}
</script>

<style lang="scss">
.tip{
  margin-top: 10px;
  margin-bottom: -10px;
  font-size: 10px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  text-align: center;
}
.tip_bottom{
  margin-top: 20px;
  // margin-bottom: 10px;
  font-size: 10px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  text-align: center;
}
.comments{
  display: flex;
  flex-direction: column;
  height: 0px; //必须设置高度，否则flex-grow无效
  flex-grow: 1;  //设置为1 -> 当父元素有剩余空间是将拉伸当前元素
  // overflow-y: scroll;
  overflow: auto;
  &::-webkit-scrollbar{
    background-color:transparent;
  }

  .empty_message{
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 400;
    color: #999999;
    line-height: 18px;
    text-align: center;
  }

  .comment{

    .time{
      margin-top: 36px;
      margin-bottom: -10px;
      font-size: 10px;
      font-weight: 400;
      color: #999999;
      line-height: 18px;
      text-align: center;
    }

    .chat{
      width: 100%;
      margin-top: 24px;
      display: flex;

      .pic{
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }

      .right{
        margin-left: 8px;
        display: flex;
        flex-direction: column;

        .title{
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          line-height: 17px;
          margin-bottom: 4px;
        }

        .content{
          width: 100%;
          background: #F2F2F2;
          border-radius: 4px;
          font-size: 13px;
          font-weight: 400;
          color: #333333;
          line-height: 18px;
          padding: 8px;

          &.myself{
            background: #FFB82E;
            color: #FFFFFF;
          }
        }
      }
    }
  }
}
</style>

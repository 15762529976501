<template>
	<!-- 固定评论框 -->
	<div class="total" @mouseleave="out">
		<div class="detail_comments">
			<div class="detail_comment">
				<div class="left">
					<!-- <input class="comment_title" placeholder="我来聊几句" v-model="commentInput" @keydown.enter="sendComment"/> -->
					<!-- <textarea ref="textarea" placeholder="我来聊几句" v-model="commentInput" @keydown.enter="sendComment"
          :style="{'height': height}" class="comment_title"></textarea> -->
					<el-input type="textarea" :autosize="{ minRows: 1, maxRows: 3.5}" placeholder="我来聊几句" v-model="commentInput"
					 @focus="focusInput" @keydown.native="sendComment" class="comment_title">
					</el-input>
				</div>
				<div class="right">
					<img class="icon" @click="showEmoji" :src="isEmojiClick ? 'https://assetcloud-wxapp.oss-cn-hangzhou.aliyuncs.com/3.0.0/meeting_keyword.png' : 'https://assetcloud-wxapp.oss-cn-hangzhou.aliyuncs.com/3.0.0/meeting_smile.png'" />
					<img class="icon" @click="addLike" :src="isLikeClick!=0 ? 'https://assetcloud-wxapp.oss-cn-hangzhou.aliyuncs.com/3.0.0/meeting_zan_yellow.png' : 'https://assetcloud-wxapp.oss-cn-hangzhou.aliyuncs.com/3.0.0/meeting_zan.png'" />
					<span class="like_count">{{isLikeClick==0? '': isLikeClick}}</span>
				</div>
			</div>
		</div>
		<div class="emoji_show" v-show="isEmojiClick">
			<span class="emoji" v-for="item in emojiList" @click="addToComment(item)">
				{{item}}
			</span>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			comment: {
				type: Object,
				default () {
					return {}
				}
			},
			isLoading: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				isLikeClick: 0,
				isEmojiClick: false,
				emojiList: ['😀', '😁', '😂', '🤣', '😃', '😄', '😅', '😉', '😊', '😋', '😎', '😍', '😘', '😗', '😙', '🤗', '🤩',
					'🤔',
					'🤨', '😐', '😑', '😶', '🙄', '😏', '😣', '😥', '😮', '🤐', '😯', '😪', '😫', '😴', '😌', '😛', '😜', '😝', '🤤',
					'😒', '😓', '😔',
					'🧐', '🤓', '🤭', '🤫', '😡', '❤️', '💘', '💓', '💕', '💖', '💗', '💙', '💚', '💛', '🧡', '💜', '🖤', '💝', '💞',
					'👻'
				],
				commentInput: ''
			}
		},
		computed: {
			commentList() {
				return this.$store.state.index.commentList
			}
		},
		methods: {
			out() {
				if (this.isEmojiClick) {
					this.showEmoji()
				}
			},
			focusInput() {
				this.$emit('scroll-to-bottom', 'input')
			},
			showEmoji() {
				this.isEmojiClick = !this.isEmojiClick
				if (this.isEmojiClick) {
					// $('.total')[0].style.height = '200px'
					this.$emit('to-bottom', '130px')
					this.$emit('scroll-to-bottom', 'input')
				} else {
					// $('.total')[0].style.height = ''
					this.$emit('to-bottom', '0px')
				}
			},
			addLike() {
				this.isLikeClick++
			},
			addToComment(value) {
				this.commentInput += value
			},
			sendComment(event) {
				if (event.keyCode == 13) {
					if (!event.metaKey) {
						event.preventDefault()
						this.commentList.list.push({
							id: 3,
							img: 'https://assetcloud-wxapp.oss-cn-hangzhou.aliyuncs.com/3.0.0/meeting_default_avatar.png',
							title: 'LLL',
							comment: this.commentInput
						})
						this.commentInput = ''
						this.$store.commit('index/SET_COMMENT_LIST', {
							list: this.commentList.list,
							total: this.commentList.total + 1
						})
						this.$store.commit('index/SET_COMMENT_TYPE', 'send')
					} else {
						this.messageTxt = this.messageTxt + '\n'
					}
				}
			}
		}
	}
</script>
<style lang="scss">
	// 固定评论框
	.total {
		// height: 60px;
		display: flex;
		flex-direction: column;
		position: fixed;
		bottom: 0px;
		left: 15px;
		right: 15px;
		z-index: 2;
		background: #F7F7F7;
	}

	.detail_comments {
		// height: 60px;
		background: #F7F7F7;
		padding: 14px 15px;
		// position: fixed;
		// bottom: 14px;
		// left: 15px;
		// right: 15px;
	}

	.detail_comment {
		// height: 32px;
		display: flex;

		.left {
			width: 100%;
			// height: 32px;
			background: #FFFFFF;
			box-shadow: 0px 1px 10px 0px rgba(211, 211, 211, 0.14);
			border-radius: 16px;
			display: flex;
			align-items: center;
		}

		.el-textarea {
			width: 100%;
		}

		.comment_title {
			width: 100%;
			margin: 7px 15px;
			line-height: 18px;

			// 解决ios宽度兼容问题
			textarea {
				width: 100% !important;
				font-size: 13px;
				font-weight: 400;
				color: #999999;
			}

			// ios不兼容，舍去
			// /deep/.el-textarea__inner {
			//   width: 100% !important;
			//   font-size: 13px;
			//   font-weight: 400;
			//   color: #999999;
			// }
		}

		.right {
			display: flex;
			align-items: flex-end;
			margin: 4px 0 4px 15px;

			.icon {
				width: 24px;
				height: 24px;
				margin-right: 15px;
			}

			.like_count {
				margin-left: -11px;
				font-size: 13px;
				font-weight: 400;
				color: #FFB82E;
				line-height: 24px;
			}
		}
	}

	.emoji_show {
		background: #FFFFFF;
		box-shadow: 0px 1px 10px 0px rgba(211, 211, 211, 0.14);
		padding: 0px 15px;
		margin-bottom: 10px;
		border-radius: 16px;
		height: 120px;
		// height: 0px; //必须设置高度，否则flex-grow无效
		// flex-grow: 1;  //设置为1 -> 当父元素有剩余空间是将拉伸当前元素
		// overflow-y: scroll;
		overflow: auto;

		.emoji {
			line-height: 40px;
			font-size: 20px;
		}
	}

	// 骨架
	// .c-comment_title_skeleton {
	// 	width: 262rpx;
	// 	height: 32rpx;
	// 	background-color: #eee;
	// }

	// .c-collection_title_skeleton {
	// 	width: 56rpx;
	// 	height: 40rpx;
	// 	background-color: #eee;
	// }
</style>

<template>
  <!-- 直播h5 -->
  <div class="content">
    <div id="player" ref="player"></div>
	<!-- <liveplayer></liveplayer> -->
	<!-- <video-js></video-js> -->
    <!-- 直播信息 -->
    <div class="test" ref="test">
      <intro :data="introList"></intro>
      <div class="live_tab" ref="live_tab">
        <div>
          <div v-for="(item, index) in liveTabList" :key="index" :class="'live_tab_list ' + (index === tabCur ? 'tabs__item_cur': '')" @click="_onToggleCategory(index)">
            <div v-show="item.title!=='往期列表'||(item.title==='往期列表'&&introList.liveStatus===2&&replayList.length>1)">{{item.title}}</div>
            <div v-show="index===tabCur" class="live_tab_line"></div>
          </div>
        </div>
        <div class="box">
          <div class="live_tab_content" v-show="liveTabList[tabCur].title==='直播介绍'">
            <!-- <div class="information" v-html="liveTabList[tabCur].content" /> -->
            <div class="information">
              <img class="info_img" src="https://assetcloud-wxapp.oss-cn-hangzhou.aliyuncs.com/3.0.0/meeting_info.png"/>
              <div class="info_title">
                <span class="number">01/</span>
                直播背景与目的
              </div>
              <div class="info_content">
                2020年11月14日，由中兴新云服务有限公司主办，ACCA（特许公认会计师公会）、中国总会计师协会信息化分会等协办，工业和信息通信业管理会计推广应用联盟支持的“2020 中国财务云创新大会·成都峰会”在蓉城成功举办！本次活动汇聚了200余位来自各大企事业单位、高校、会计师事务所、管理咨询公司和国际会计师组织等的管理者和行业专家，围绕“财务数字新基建”主题分享专业洞察与实践经验。
              </div>
            </div>
          </div>
          <!-- <div class="live_tab_chat" v-show="liveTabList[tabCur].title==='互动聊天'">
            <chatroom ref="chatroom"></chatroom>
          </div>
          <div class="live_tab_replay" v-show="liveTabList[tabCur].title==='往期列表'">
            <replay @on-play-video="onPlayVideo"></replay>
          </div> -->
        </div>
        <chatroom-comment @to-bottom="toBottom" @scroll-to-bottom="_onScrollToBottom" ref="comment" v-show="liveTabList[tabCur].title==='互动聊天'"></chatroom-comment>
      </div>
    </div>
  </div>
</template>

<script>
// import ErrorResult from '@/components/ErrorResult.vue'
import liveplayer from '@/components/liveplayer/liveplayer.vue'
import videoJs from '@/components/liveplayer/videoJs.vue'
import intro from '@/components/intro/intro.vue'
import chatroomComment from '@/components/chatroom/chatroomComment.vue'
import chatroom from '@/components/chatroom/chatroom.vue'
import replay from '@/components/replay/replay.vue'
import VideoPlayer from '@/utils/play.js'
export default {
  name: 'live',
  props: {},
  components: {
    // ErrorResult,
	liveplayer,
	videoJs,
    intro,
    chatroomComment,
    chatroom,
    replay
  },
  data () {
    return {
      introList: {
        title: '2020中国企业财资管理创新发展报告发布会——暨浦发银行账管家发布会',
        // 时间格式如下，2020-11-21 ios不支持
        liveTime: '2020/12/01 17:00:00',
        watchCount: 2021,
        likeCount: 202,
        liveStatus: 1 // 0未开始，1正在直播, 2直播结束
      },
      countdown: {
        day: '',
        hour: '',
        minute: '',
        second: ''
      },
      // countCover: false, // 倒计时是否结束
      tabCur: 0,
      isLive: true,
      liveTabList: [
        {
          title: '直播介绍',
          content: `<h1>01/ 活动主题</h1><h1>“享数智，赢未来”</h1><h1>——</h1>2020中国企业财资管理创新发展报告发布会暨浦发银行账管家发布会。<h1>01/ 活动主题</h1><h1>“享数智，赢未来”</h1><h1>——</h1>2020中国企业财资管理创新发展报告发布会暨浦发银行账管家发布会。<h1>01/ 活动主题</h1><h1>“享数智，赢未来”</h1><h1>——</h1>2020中国企业财资管理创新发展报告发布会暨浦发银行账管家发布会。<h1>01/ 活动主题</h1><h1>“享数智，赢未来”</h1><h1>——</h1>2020中国企业财资管理创新发展报告发布会暨浦发银行账管家发布会。`
        }
        // {
        //   title: '互动聊天'
        // },
        // {
        //   title: '往期列表'
        // }
      ],
      sourceVod: '//player.alicdn.com/resource/player/qupai.mp4', // 暖场视频或图片
      sourceList: '//live.treasury.com.cn/chenhu/caizi_lud.m3u8',
      // sourceList: '//live.treasury.com.cn/test/ttt.m3u8',
      source: '', // 视频来源
      cover: 'http://liveroom-img.oss-cn-qingdao.aliyuncs.com/logo.png',
      chatroomClick: 0
    }
  },
  computed: {
    replayList () {
      return this.$store.state.index.replayList
    }
  },
  mounted () {
    if (this.introList.liveStatus === 0) {
      this.source = this.sourceVod
      this.onLivePlay()
    } else if (this.introList.liveStatus === 1) {
      this.isLive = true
      this.source = this.sourceList
      this.onLivePlay()
      if (this.player.player.played === false) {
        // this.player.loadByUrl(this.sourceVod)
      }
    } else {
      this.isLive = false
      // this.cover = this.replayList[0].pic
      this.source = this.replayList[0].url
      this.onLivePlay()
    }
    // this.onShowChatroom()
  },
  methods: {
    onLivePlay () {
      const that = this
      this.videoHeight = Math.ceil(document.documentElement.clientWidth * 307 / 546)
      this.$refs.test.style.top = this.videoHeight + 'px'
      this.$refs.test.style.height = document.documentElement.clientHeight - this.videoHeight + 'px'
      this.player = new VideoPlayer({
        id: 'player',
        autoplay: false, // 自动播放
        isLive: that.isLive,
        height: that.videoHeight + 'px',

        playsinline: true,
        controlBarVisibility: 'hover',
        // source: '//player.alicdn.com/resource/player/qupai.mp4',
        source: that.source,
        useH5Prism: true,
        useFlashPrism: false,
        x5_video_position: 'normal',
        // prismplayer 2.0.1版本支持的属性，主要用户实现在android 微信上的同层播放
        x5_type: 'h5', // 通过 video 属性 “x5-video-player-type” 声明启用同层H5播放器，支持的值：h5 https://x5.tencent.com/tbs/guide/video.html
        cover: that.cover
      })
      console.log(this.player)
    },
    onPlayVideo (param) {
      this.player.loadByUrl(param.url)
    },
    onShowChatroom () {
      // let wrapper = $('.comment-list');
      // let comment = new Comment(wrapper);
      // // let remainHeight = util.screenHeight() - $('.comment-textbox').height();
      // let remainHeight = '400px';
      // $('.comment-list .comment-container').css('max-height',remainHeight);
    },
    toBottom (param) {
      let ele = this.$refs.test
      this.$nextTick(() => {
        ele.scrollTop = ele.scrollHeight
        this.$refs.live_tab.style.bottom = param
        // $('.live_tab_chat')[0].style.marginBottom = param
      })
    },
    _onToggleCategory (e) {
      this.tabCur = e
      if (this.liveTabList[e].title === '互动聊天') {
        if (this.chatroomClick === 0) {
          this._onScrollToBottom()
        }
        this.chatroomClick++
      }
      // if(this.liveTabList[e].title==='直播介绍'){
      //   $('.test')[0].style.height = '100%'
      // }else{
      //   $('.test')[0].style.height = document.documentElement.clientHeight - this.videoHeight +'px'
      // }
    },
    _onScrollToBottom (param) {
      // 触发子组件的方法，聊天框置底
      this.$refs.chatroom.scrollToBottom(param)
      if (param === 'input') {
        let ele = this.$refs.test
        ele.scrollTop = ele.scrollHeight
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";
.content {
  width: 100%;
  // height: 100%;
  position: relative;
  font-family: PingFangSC-Semibold, PingFang SC;
}

#player{
  position: fixed;
  top: 0;
  z-index: 2;
}
.test{
  // height: 0px; //必须设置高度，否则flex-grow无效
  // flex-grow: 1;  //设置为1 -> 当父元素有剩余空间是将拉伸当前元素
  // overflow-y: scroll;
  
  position: relative;
  overflow: auto;
  z-index: 1;
  // height: 100%;
  &::-webkit-scrollbar{
       background-color:transparent;
    }
}

.live_tab{
  position: relative;
  margin: 16px 15px 40px 15px;
  // touch-action: none;
  // height: 80%;

  .live_tab_list{
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
    margin-right: 30px;

    .live_tab_line{
      margin-top: 4px;
      width: 24px;
      height: 2px;
      background: #AD0610;
      border-radius: 1px;
      margin-left: calc(50% - 12px);
    }
  }

  .tabs__item_cur{
    color: #AD0610;
  }

  .box{
    // height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 16px 0px rgba(211, 211, 211, 0.2);
    margin-top: 12px;

    .live_tab_content{
      background: #FFFFFF;
      padding: 18px 15px 20px;
      // margin-bottom: 40px;
      border-radius: 6px;
      // height: 100%;
      display: flex;
      flex-direction: column;

      .information{
        // height: 0px; //必须设置高度，否则flex-grow无效
        // flex-grow: 1;  //设置为1 -> 当父元素有剩余空间是将拉伸当前元素
        // overflow-y: scroll;
        // overflow: auto;
        // &::-webkit-scrollbar{
        //   background-color:transparent;
        // }

        .info_img{
          width: 100%;
        }
        .info_title{
          font-size: 16px;
          font-weight: bold;
          color: #0A8EFF;
          line-height: 29px;
          margin: 10px 0;

          .number{
            font-size: 21px;
            font-weight: 800;
          }
        }
        .info_content{
          font-size: 13px;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
        }
      }
    }

    .live_tab_chat{
      // height: 100%;
      display: flex;
      flex-direction: column;
      background: #FFFFFF;
      padding: 18px 15px 20px;
      border-radius: 6px 6px 0px 0px;
      margin-bottom: 60px;
    }

    .live_tab_replay{
      background: #FFFFFF;
      padding: 20px 15px;
      margin-bottom: 40px;
      border-radius: 6px;
      // height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

$namespace: "template";
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import '@/styles/index.scss' // global css
import 'lib-flexible'
import flvjs from 'flvjs'
import videojs from 'video.js'
import {
  Input
} from 'element-ui'
Vue.use(Input)
Vue.use(flvjs)
Vue.config.productionTip = false
Vue.prototype.$video = videojs
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
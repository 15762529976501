<template>
  <div class="intro">
    <div class="left"></div>
    <div class="right">
      <div class="title">{{data.title}}</div>
      <div class="times">
        <div class="time">
          <img class="time_icon" src="https://assetcloud-wxapp.oss-cn-hangzhou.aliyuncs.com/3.0.0/meeting_clock.png"/>
          <div class="time_name">直播时间：</div>
          <div class="time_live">
            {{timeData}}
          </div>
        </div>
        <div class="time" v-show="data.liveStatus===0">
          <img class="time_icon" src="https://assetcloud-wxapp.oss-cn-hangzhou.aliyuncs.com/3.0.0/meeting_live.png"/>
          <div class="time_name">距离直播：</div>
          <div class="time_live_two" v-show="countCover===false">
            <div class="time_number" style="margin-left: 0;">{{countdown.day}}</div>天
            <div class="time_number">{{countdown.hour}}</div>时
            <div class="time_number">{{countdown.minute}}</div>分
            <div class="time_number">{{countdown.second}}</div>秒
          </div>
          <div class="time_live_two" v-show="countCover===true">
            <div class="time_status" style="margin-left: 0;">直播未开始</div>
          </div>
        </div>
        <div class="time" v-show="data.liveStatus!==0">
          <img class="time_icon" src="https://assetcloud-wxapp.oss-cn-hangzhou.aliyuncs.com/3.0.0/meeting_live.png"/>
          <div class="time_name">直播状态：</div>
          <div class="time_live_two">
            <div class="time_status" style="margin-left: 0;">{{data.liveStatus===1?'直播进行中':'直播结束'}}</div>
          </div>
        </div>
        <div class="watchAndLike" v-show="data.liveStatus!==0">
          <div class="watch">
            <img class="watch_icon" src="https://assetcloud-wxapp.oss-cn-hangzhou.aliyuncs.com/3.0.0/meeting_eye.png" />
            <div class="watch_count">{{data.watchCount}}</div>次观看
          </div>
          <div class="like" v-show="data.liveStatus===1">
            <img class="like_icon" src="https://assetcloud-wxapp.oss-cn-hangzhou.aliyuncs.com/3.0.0/meeting_live_zan.png" />
            <div class="like_count">{{data.likeCount}}</div>个赞
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    data: {
				type: Object,
				default () {
					return {}
				}
			}
  },
  data () {
      return {
        countdown:{
          day: '',
          hour: '',
          minute: '',
          second: ''
        },
        countCover: false // 倒计时是否结束
        // liveStatus: 0 // 0未开始，1正在直播, 2直播结束
      }
    },
    computed:{
      timeData() {
        let timeArr = this.data.liveTime.split(' ')
        let day = timeArr[0].split('/')
        let minute = timeArr[1].split(':')
        return day[0]+'年'+day[1]+'月'+day[2]+'日 ' +minute[0]+':'+minute[1]+':'+minute[2]
      }
    },
    mounted () {
      if(this.data.liveStatus === 0){
        this.onCountDown()
      }
    },
    methods: {
      onCountDown(){
        let that = this
        let timer = window.setInterval(function(){
          let now = new Date()
          let pre = new Date(that.data.liveTime)
          let diff = pre.getTime() - now.getTime()
          let day = 0, hour = 0, minute = 0, second = 0
          if(diff>0){
            day = Math.floor(diff / (1000 * 60 * 60 * 24))
            let tmp = diff % (1000 * 60 * 60 * 24)
            hour = Math.floor(tmp / (1000 * 60 * 60))
            tmp = tmp % (1000 * 60 * 60)
            minute = Math.floor(tmp / (1000 * 60))
            tmp = tmp % (1000 * 60)
            second = Math.floor(tmp / 1000)
            that.countdown = {
              day: day < 10 ? '0'+ day : day,
              hour: hour < 10 ? '0'+ hour : hour,
              minute: minute < 10 ? '0'+ minute : minute,
              second: second < 10 ? '0'+ second : second
            }
           }else{
             clearInterval(timer)
             that.countCover = true
             // that.isLive = false
             that.source = that.sourceVod
             that.onLivePlay()
           }
        },1000)
      }
    }

}
</script>

<style lang="scss">
.intro{
  position: relative;
  margin: 16px 15px;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px 0px rgba(211, 211, 211, 0.2);
  border-radius: 6px;
  overflow: hidden;

  .left {
    position: absolute;
    top: 24px;
    left: 0;
    width: 3px;
    height: 16px;
    background: #AD0610;
    border-radius: 100px 100px 0px 0px;
  }

  .right{
    margin: 20px 15px;
  }

  .title {
    position: relative;
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    line-height: 22px;
    padding: 0 15px;
  }

  .times{
    margin-left: 16px;
    margin-bottom: 26px;

    .time{
      display: flex;
      margin-top: 10px;
      align-items:flex-end;
      font-size: 13px;
      line-height: 18px;

      .time_icon{
        width: 16px;
        height: 16px;
        margin: 1px 0;
      }
      .time_name{
        color: #999999;
        margin-left: 3px;
      }
      .time_live{
        font-weight: 400;
        color: #0A8EFF;
        // line-height: 22px;
        margin-left: 2px;
      }
      .time_live_two{
        display: flex;
        font-weight: 400;
        color: #999999;
        // line-height: 22px;
        align-items:flex-end;

        .time_number{
          font-size: 21px;
          font-weight: 600;
          color: #0A8EFF;
          line-height: 25px;
          letter-spacing: 1px;
          margin-left: 12px;
          margin-right: 2px;
        }

        .time_status{
          color: #0A8EFF;
        }
      }
    }
  }

  .watchAndLike{
    display: flex;
    margin-top: 18px;

    .watch{
      display: flex;
      width: 50%;
      font-size: 13px;
      font-weight: 400;
      color: #999999;
      line-height: 18px;

      .watch_icon{
        width: 16px;
        height: 16px;
      }
      .watch_count{
        color: #0A8EFF;
        margin-left: 3px;
      }
    }

    .like{
      display: flex;
      font-size: 13px;
      font-weight: 400;
      color: #999999;
      line-height: 18px;

      .like_icon{
        width: 16px;
        height: 16px;
      }
      .like_count{
        color: #0A8EFF;
        margin-left: 3px;
      }
    }
  }
}
</style>

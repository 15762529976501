<template>
  <div class="replays">
    <div v-for="item in replayList" class="replay_card" @click="onPlayVideo(item)">
      <img :src="item.pic" class="card_left" />
      <div class="card_right">
        <div class="title">{{item.title}}</div>
        <div class="time">{{item.time}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default{
  data () {
    return {
    }
  },
  computed: {
    replayList () {
      return this.$store.state.index.replayList
    }
  },
  methods: {
    onPlayVideo (param) {
      this.$emit('on-play-video', param)
    }
  }
}
</script>

<style lang="scss">
.replays{
  height: 0px; //必须设置高度，否则flex-grow无效
  flex-grow: 1;  //设置为1 -> 当父元素有剩余空间是将拉伸当前元素
  // overflow-y: scroll;
  overflow: auto;

  .replay_card{
    display: flex;
    margin-bottom: 24px;
    // height: 100%;

    .card_left{
      width: 114px;
      height: 63px;
      margin-right: 12px;
    }

    .card_right{
      position: relative;

      .title{
        font-size: 13px;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
      }
      .time{
        font-size: 10px;
        font-weight: 400;
        color: #999999;
        line-height: 12px;
        position: absolute;
        bottom: 0;
      }
    }
  }
}
</style>
